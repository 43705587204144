import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
const prefix2 = '/api/dscloud-appservice-management'
/**
 * @description: 政策通报列表
 * @param {*} data page:当前页  size:当前条数 projectId:所属项目  policyPopup:是否弹窗提醒 policyPublishedStatus:发布状态  
 * @return {*}
 */
export function policyReportList (data) {
  return fetchApi(URL.POLICY_REPORT_LIST, data, 'get','params',true)
}
/**
 * @description: 政策通报管理发布/取消发布
 * @param {*} policyId 政策ID
 * @param {*} policyPublishedStatus 发布/取消发布
 * @return {*}
 */
export function policyReportPublish (policyId, policyPublishedStatus) {
  return fetchApi(URL.POLICY_REPORT_PUBLISH + '/' + policyId + '/' + policyPublishedStatus, null, 'put')
}
/**
 * @description: 政策通报管理数据统计
 * @param {*} data size:当前条数 page:当前页 dataType:列表类型
 * @return {*}
 */
export function policyReportDataStatistic (data) {
  return fetchApi(URL.POLICY_REPORT_DATA_STATISTIC, data, 'get')
}
/**
 * @description: 政策通报添加
 * @param {*} data
 * @return {*}
 */
export function policyReportAdd (data) {
  return fetchApi(URL.POLICY_REPORT_ADD, data, 'post', 'data')
}
/**
 * @description: 政策通报管理详情
 * @param {*} policyId 政策通报ID
 * @return {*}
 */
export function policyReportDetail (policyId) {
  return fetchApi(URL.POLICY_REPORT_DETAIL + '/' + policyId, {}, 'get','params',true)
}
/**
 * @description: 政策通报管理删除
 * @param {*} policyId 政策通报ID
 * @return {*}
 */
export function policyReportDelete (policyId) {
  return fetchApi(URL.POLICY_REPORT_DELETE + '/' + policyId, null, 'delete')
}
/**
 * @description: 政策通报管理编辑
 * @param {*} data
 * @return {*}
 */
export function policyReportEdit (data) {
  return fetchApi(URL.POLICY_REPORT_EDIT, data, 'put', 'data')
}
/**
 * @description:政策类型管理列表 
 * @param {*} data page:当前页  size:当前条数 projectId:所属项目  policyTypeName:政策名称 policyTypeStatus:政策状态
 * @return {*}
 */
export function policyTypeList (data) {
  return fetchApi(URL.POLICY_TYPE_LIST, data, 'get','params',true)
}
/**
 * @description: 政策类型添加
 * @param {*} data
 * @return {*}
 */
export function policyTypeAdd (data) {
  return fetchApi(URL.POLICY_TYPE_ADD, data, 'post', 'data')
}
/**
 * @description: 政策类型启用/禁用
 * @param {*} policyTypeId 政策类型ID
 * @param {*} policyTypeStatus 政策状态
 * @return {*}
 */
export function policyTypeEnable (policyTypeId, policyTypeStatus) {
  return fetchApi(URL.POLICY_TYPE_ENABLE + '/' + policyTypeId + '/' + policyTypeStatus, null, 'put')
}
/**
 * @description: 政策类型删除
 * @param {*} policyTypeId 政策类型ID
 * @return {*}
 */
export function policyTypeDelete (policyTypeId) {
  return fetchApi(URL.POLICY_TYPE_DELETE + '/' + policyTypeId, null, 'delete')
}
/**
 * @description: 政策类型编辑
 * @param {*} data
 * @return {*}
 */
export function policyTypeEdit (data) {
  return fetchApi(URL.POLICY_TYPE_EDIT, data, 'put')
}
/**
 * @description: 政策类型详情
 * @param {*} policyTypeId 政策类型ID
 * @return {*}
 */
export function policyTypeDetail (policyTypeId) {
  return fetchApi(URL.POLICY_TYPE_DETAIL + '/' + policyTypeId, {}, 'get','params',true)
}
/**
 * @description: 获取所属项目
 * @return {*}
 */
export function getProjectList () {
  return fetchApi(URL.GET_ALL_PROJECT_LIST, null, 'get')
}
/**
 * @description: 获取政策类型
 * @return {*}
 */
export function getPolicyType () {
  return fetchApi(URL.POLICY_TYPE, {}, 'get','params',true)
}
// 顺义获取项目接口
export function getShunyiAllProject(data) {
  return fetchApi(URL.GET_SHUNYI_ALL_PROJECTLIST, data, 'post', 'data',true);
}

export function getModuleList(data) {
  return fetchApi(prefix2+ '/web/module/findBindingModule', data, 'post', 'data',true);
}