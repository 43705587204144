<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-10-26 19:17:02
 * @Description: 政策通报添加/编辑
-->
<template>
  <div style="overflow-y: scroll;" :style="{ height: height }">
    <div class="header">
      <ds-header :title="headerTitle" style="width: 100%"></ds-header>
      <a-button type="primary" @click="() => this.$router.go(-1)">返回</a-button>
    </div>
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" :rules="rules">
      <a-form-model-item label="政策标题" prop="policyTitle">
        <a-input
          style="width: 800px"
          placeholder="请输入政策标题，不超过50个字符"
          :max-length="50"
          v-model.trim="form.policyTitle"
        />
      </a-form-model-item>
      <a-form-model-item label="政策简介">
        <a-textarea
          style="width: 800px"
          placeholder="请输入政策简介，不超过100个字符"
          :max-length="100"
          v-model.trim="form.policyIntroduce"
          :autosize="{ minRows: 2, maxRows: 2 }"
        />
      </a-form-model-item>
      <a-form-model-item label="政策申报日期">
        <a-range-picker
          :getCalendarContainer="
            (triggerNode) => {
              return triggerNode.parentNode;
            }
          "
          format="YYYY-MM-DD"
          @change="onChangeTime"
          style="width: 800px"
          v-model="policyTime"
        />
      </a-form-model-item>
      <!-- 顺义新增内容 -->
      <a-form-model-item label="发文单位" >
        <a-input
          style="width: 800px"
          placeholder="请输入发文单位名称"
          :max-length="50"
          v-model.trim="form.documentUnit"
        />
      </a-form-model-item>
      <a-form-model-item label="发文字号">
        <a-input
          style="width: 800px"
          placeholder="请输入发文字号"
          :max-length="50"
          v-model.trim="form.documentNumber"
        />
      </a-form-model-item>
      <a-form-model-item label="发文时间">
        <a-date-picker  plsaceholder="请选择发文日期" v-model="form.documentTime" style="width: 800px" />
      </a-form-model-item>
      <a-form-model-item label="有效期">
        <a-range-picker
          :getCalendarContainer="
            (triggerNode) => {
              return triggerNode.parentNode;
            }
          "
          format="YYYY-MM-DD"
          @change="onChangeTime2"
          style="width: 800px"
          v-model="validityTime"
        />
      </a-form-model-item>
      <a-form-model-item label="政策内容配置" prop="policyFormat">
        <a-radio-group v-model="form.policyFormat" @change="changeConfig">
          <a-radio :value="1"> 自主编辑 </a-radio>
          <a-radio :value="2"> 第三方链接 </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item label="政策详情" :span="24" required v-show="form.policyFormat === 1">
        <div class="content">
          <div id="editer" style="width: 800px"></div>
        </div>
      </a-form-model-item>
      <a-form-model-item label="链接URL" :span="24" prop="policyLink" v-if="form.policyFormat === 2">
        <a-textarea
          style="width: 800px"
          placeholder="请输入政策链接URL，不超过2000个字符。需要添加“http://”或“https://前缀”"
          :max-length="2000"
          v-model.trim="form.policyLink"
          :autosize="{ minRows: 5, maxRows: 5 }"
        />
      </a-form-model-item>
      <a-form-model-item label="是否展示标题栏" prop="isShowTitle" v-if="form.policyFormat === 2">
        <a-radio-group v-model="form.isShowTitle">
          <a-radio :value="1"> 是 </a-radio>
          <a-radio :value="0"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="附件上传：" :span="24" v-if="form.policyFormat === 1">
        <a-upload
          name="file"
          :multiple="false"
          accept=".pdf"
          :show-file-list="false"
          :action="IMG_API + '/oss/files'"
          :beforeUpload="checkFileType"
          @change="uploadSuccess"
          :default-file-list="defaultFileList"
        >
          <a-button type="primary"> <a-icon type="upload" /> 选择文件 </a-button>
        </a-upload>

        <p class="tips-color">PDF格式，不超过5个文件，单个文件大小不超过30MB</p>
      </a-form-model-item>

      <a-form-model-item label="政策类型">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择政策类型"
          v-model="form.policyTypeId"
        >
          <a-select-option
            v-for="item in policyType"
            :key="item.policyTypeId"
            :value="item.policyTypeId"
            :disabled="item.policyTypeStatus === 1"
          >
            {{ item.policyTypeName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属项目" prop="projectIdList">
        <!-- <a-select
          mode="multiple"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 800px"
          placeholder="请选择所属项目"
          v-model="form.projectIdList"
        >
          <a-select-option v-for="item in projectList" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <el-cascader v-model="form.projectIdList"
              ref="cascader"
              :collapse-tags = "true"
              style="width: 800px"
              :options="projectList"
              @change="getModuleList"
              :props="{emitPath:false, multiple: true,checkStrictly:true, label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable>
        </el-cascader>
      </a-form-model-item>
      <a-form-model-item label="所属模块" :span="24">
        <a-select
          mode="multiple"
          v-model="form.moduleProjectList"
          style="width: 800px"
          placeholder="请选择所属模块"
        >
          <a-select-option v-for="option in moduleList" :key="option.moduleId" :value="option.moduleId">
            {{ option.moduleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="是否弹窗提醒：" :span="24" prop="policyPopup">
        <a-radio-group v-model="form.policyPopup">
          <a-radio :value="0"> 是 </a-radio>
          <a-radio :value="1"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="弹窗展示时间" prop="popupStartTime" v-if="form.policyPopup === 0">
        <a-range-picker
          format="YYYY-MM-DD"
          @change="onChangeDate"
          :disabled-date="disabledDate"
          style="width: 800px"
          v-model="popupTime"
        />
      </a-form-model-item> -->
      <a-form-model-item label="是否发布" :span="24" prop="policyPublishedStatus">
        <a-radio-group v-model="form.policyPublishedStatus">
          <a-radio :value="0"> 是 </a-radio>
          <a-radio :value="1"> 否 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 8 }">
        <a-button type="primary" @click="submit" style="margin-right: 20px"> 确定 </a-button>
        <a-button type="info" @click="goBack"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { IMG_API } from "@/config";
import wangEditor from "wangeditor";
import * as api from "@/api/entrepreneurClubManagement/policyManagement";
import moment from "moment";
import debounce from "lodash/debounce";
export default {
  name: "policyReportAdd",
  data() {
    return {
      IMG_API,
      // 标题
      headerTitle: "",
      form: {
        // 政策标题
        policyTitle: "",
        // 政策类型
        policyTypeId: undefined,
        // 政策简介
        policyIntroduce: "",
        // 所属项目
        projectIdList: [],
        // 政策详情
        policyDetail: "",
        // 附件
        policyFileList: [],
        // 是否弹窗提醒
        policyPopup: 1,
        // 是否发布
        policyPublishedStatus: 0,
        // 申报开始时间
        policyStartTime: "",
        // 申报结束时间
        policyEndTime: "",
        // 弹框结束时间
        popupEndTime: "",
        // 弹框开始时间
        popupStartTime: "",
        // 内容配置
        policyFormat: 1,
        // 政策链接URL
        policyLink: "",
        // 是否展示标题栏
        isShowTitle: 1,
        moduleProjectList:[],// 模块
        documentUnit:'',// 发文单位
        documentNumber:'',// 发文字号
        documentTime:'',// 发文时间
        documentPeriodValidityStart:'',// 发文有效时间
        documentPeriodValidityEnd:'',// 发文结束时间
      },
      rules: {
        policyTitle: [{ required: true, message: "请输入政策标题", trigger: "blur" }],
        projectIdList: [{ required: true, message: "请选择所属项目", trigger: "change" }],
        policyPopup: [{ required: true, message: "请选择是否弹窗提醒", trigger: "change" }],
        popupStartTime: [{ required: true, message: "请选择弹窗展示时间", trigger: "blur" }],
        policyPublishedStatus: [{ required: true, message: "请选择是否发布", trigger: "change" }],
        policyFormat: [{ required: true, message: "请选择政策内容配置", trigger: "change" }],
        policyLink: [
          { required: true, message: "请输入政策链接URL", trigger: "blur" },
          {
            pattern:
              /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/,
            message: "链接不符合规则，请确认链接是否添加“http://”或“https://”",
            trigger: "blur",
          },
        ],
        isShowTitle: [{ required: true, message: "请选择是否展示标题栏", trigger: "change" }],
      },
      // 政策类型
      policyType: [],
      // 项目
      projectList: [],
      // 申报时间
      policyTime: [],
      // 弹框时间
      popupTime: [],
      // 默认上传列表
      defaultFileList: [],
      moduleList:[],//模块列表
      validityTime:[],// 有效期
      height:''
    };
  },
  created() {
    if (this.$route.query.type === "edit") {
      this.headerTitle = "编辑政策通报";
      this.getDetail();
    } else {
      this.headerTitle = "新增政策通报";
    }
    // 政策类型
    this.getPolicyType();
    // 所属项目
    this.getProject();
  },
  mounted() {
    this.height = window.innerHeight-160+'px'
    const _this = this;
    const editor = new wangEditor(`#editer`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.policyDetail = newHtml;
    };
    editor.config.menus = [
      "fontSize",
      "foreColor",
      "backColor",
      "underline",
      "italic",
      "bold",
      "justify",
      "splitLine",
      "undo",
      "redo",
      "list",
      "table",
      "image",
    ];
    editor.config.showFullScreen = false;
    editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgServer = this.IMG_API + "/oss/files";
    editor.config.uploadImgShowBase64 = false;
    editor.config.showLinkImg = false;
    editor.config.showLinkVideo = false;
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = "file";
    editor.config.uploadVideoName = "file";
    editor.config.uploadVideoTimeout = 60000;
    editor.config.uploadImgTimeout = 60000;
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function (xhr, editor, files) {
        console.log(xhr);
        console.log(editor);
        console.log(files);
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        let videoHTML =
          '&nbsp;<video poster="' +
          result.redirect_uri +
          '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
          result.redirect_uri +
          '" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do("insertHTML", videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      },
    };
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function (xhr) {},
      // 视频上传并返回了结果，视频插入已成功
      success: function (xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function (xhr, editor, resData) {
        _this.$message.error("上传请求失败");
      },
      // 上传视频超时
      timeout: function (xhr) {
        _this.$message.error("上传请求超时");
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function (insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri);
      },
    };
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case "success":
          _this.$message.success(s);
          break;
        case "info":
          _this.$message.info(s);
          break;
        case "warning":
          _this.$message.warning(s);
          break;
        case "error":
          _this.$message.error(s);
          break;
        default:
          _this.$message.info(s);
          break;
      }
    };
    editor.config.placeholder = "请输入政策详情";
    // 创建编辑器
    editor.create();
    this.editor = editor;
  },
  methods: {
    // 获取详情
    async getDetail() {
      try {
        const policyId = this.$route.query.id;
        const res = await api.policyReportDetail(policyId);
        if (res.code === "200") {
          res.data.projectIdList = [];
          res.data.policyTypeId = undefined;
          this.form = JSON.parse(JSON.stringify(res.data))  ;
          this.form.moduleProjectList = []
          if (this.form.policyStartTime) {
            this.form.policyStartTime = moment(this.form.policyStartTime).format("YYYY-MM-DD");
          }
          if (this.form.policyEndTime) {
            this.form.policyEndTime = moment(this.form.policyEndTime).format("YYYY-MM-DD");
          }
          if (this.form.popupStartTime) {
            this.form.popupStartTime = moment(this.form.popupStartTime).format("YYYY-MM-DD");
          }
          if (this.form.popupEndTime) {
            this.form.popupEndTime = moment(this.form.popupEndTime).format("YYYY-MM-DD");
          }
          if (this.form.documentPeriodValidityStart) {
            this.form.documentPeriodValidityStart = moment(this.form.documentPeriodValidityStart).format("YYYY-MM-DD");
          }
          if (this.form.documentPeriodValidityEnd) {
            this.form.documentPeriodValidityEnd = moment(this.form.documentPeriodValidityEnd).format("YYYY-MM-DD");
          }
          this.editor.txt.html(this.form.policyDetail);
          this.policyTime.push(this.form.policyStartTime);
          this.policyTime.push(this.form.policyEndTime);
          this.popupTime.push(this.form.popupStartTime);
          this.popupTime.push(this.form.popupEndTime);
          this.validityTime.push(this.form.documentPeriodValidityStart)
          this.validityTime.push(this.form.documentPeriodValidityEnd)
          this.form.policyTypeId = res.data.typeProjectList[0].policyTypeId;
          const idList = res.data.typeProjectList[0].projectIdList;
          this.form.projectIdList.push(...idList);
          this.getModuleList()
          let fileObj = {
            uid: "",
            name: "",
            status: "done",
            url: "",
            size: "",
          };
          console.log(res.data.moduleProjectList);
          res.data.moduleProjectList.forEach(item=>{
            console.log('==============<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            this.form.moduleProjectList.push(item.moduleId)
          })
          console.log( this.form.moduleProjectList,'=================>');
          this.form.policyFileList.forEach((item, index) => {
            fileObj = {
              uid: index,
              name: item.name,
              url: item.url,
              status: "done",
              size: item.size,
            };
            this.defaultFileList.push(fileObj);
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error,'==================>');
      }
    },
    // 确定
    submit: debounce(function () {
      let obj = {
        ...this.form,
      };
      obj.documentTime = obj.documentTime?moment(obj.documentTime).format("YYYY-MM-DD"):'';
      obj.moduleProjectList =  this.moduleList.filter(item => {
        return obj.moduleProjectList.includes(item.moduleId)
      })
      let tempObj = {
        policyTypeId: obj.policyTypeId,
        projectIdList: obj.projectIdList,
      };
      let tempArray = [];
      tempArray.push(tempObj);
      obj.policyTypeProjectList = tempArray;
      let policyFileList = [];
      let tempFileArray = [];
      if (obj.policyFileList.length) {
        obj.policyFileList.forEach((item) => {
          let temp = {
            size: "",
            name: "",
            url: "",
          };
          temp.size = item.size;
          if (item.response) {
            temp.url = item.response.redirect_uri;
            temp.name = item.response.name;
          } else {
            temp.url = item.url;
            temp.name = item.name;
          }
          tempFileArray.push(temp);
          policyFileList = [...tempFileArray];
        });
      }
      !!obj.policyTypeId ? "" : obj.policyTypeId;
      obj.policyFileList = policyFileList;
      if (obj.policyDetail === "" && obj.policyFormat === 1) {
        this.$message.error("请填写政策详情");
        return;
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.form.policyId) {
            try {
              const res = await api.policyReportEdit(obj);
              if (res.code === "200") {
                this.$message.success("编辑成功");
                this.$router.push({
                  path: "/entrepreneurClubManagement/policyManagement/policyReportManagement",
                });
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          } else {
            try {
              const res = await api.policyReportAdd(obj);
              if (res.code === "200") {
                this.$message.success("新增成功");
                this.$router.push({
                  path: "/entrepreneurClubManagement/policyManagement/policyReportManagement",
                });
              } else {
                this.$message.error(res.msg);
              }
            } catch (error) {}
          }
        } else {
        }
      });
    }, 300),
    // 取消
    goBack() {
      this.$router.go(-1);
    },
    // 上传类型校验
    checkFileType(file) {
      console.log(this.form.policyFileList.length);
      const fileNum = this.form.policyFileList.length < 5;
      if (!fileNum) {
        this.$message.error("最多上传5个附件");
        return Promise.reject();
      }
      const isJpgOrPng = file.type === "application/pdf";
      if (!isJpgOrPng) {
        this.$message.error("请上传pdf格式");
        return Promise.reject();
      }
      // 校验大小
      const isLtMB = file.size / 1024 / 1024 <= 30;
      if (!isLtMB) {
        this.$message.error("文件最大30M");
        return Promise.reject();
      }

      return fileNum && isJpgOrPng && isLtMB;
    },
    // 上传完成
    uploadSuccess(info) {
      let policyFileList = [];
      policyFileList = info.fileList;
      this.form.policyFileList = [...policyFileList];
      policyFileList = [];
    },
    // 政策申报时间处理
    onChangeTime(date, dateString) {
      this.form.policyStartTime = dateString[0];
      this.form.policyEndTime = dateString[1];
    },
    // 有效期时间处理
    onChangeTime2(date, dateString) {
      this.form.documentPeriodValidityStart = dateString[0];
      this.form.documentPeriodValidityEnd = dateString[1];
    },
    // // 弹窗时间
    // onChangeDate(date, dateString) {
    //   this.form.popupStartTime = dateString[0];
    //   this.form.popupEndTime = dateString[1];
    // },
    // // 限制时间
    // disabledDate(current) {
    //   // Can not select days before today
    //   return current && current <= moment().subtract(1, "days").endOf("day");
    // },
    // 获取政策类型
    async getPolicyType() {
      try {
        const res = await api.getPolicyType();
        if (res.code === "200") {
          this.policyType = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {
        console.log(error,'=============>');
      }
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject({ check_type: true });
        if (res.code === "200") {
          this.projectList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    changeConfig() {
      if (this.form.policyFormat === 2) {
        this.form.policyDetail = "";
        this.editor.txt.html(this.form.policyDetail);
      } else {
        this.form.policyLink = "";
        this.$refs.ruleForm.clearValidate("policyLink");
      }
    },
    async getModuleList(){
      this.form.moduleList = []
      let res = await api.getModuleList({moduleType:7,projectIdList:this.form.projectIdList})
      this.moduleList = res.data||[]
    }
  },
};
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: space-between;
}
.tips-color {
  color: #d9d9d9;
  height: 20px;
  line-height: 20px;
  margin: 2px;
}
::v-deep .w-e-toolbar {
  z-index: 3 !important;
}

::v-deep .w-e-text-container {
  z-index: 1 !important;
}
::v-deep .ant-upload-list{
  width: 350px;
}
</style>